import React from "react";
import Content from "../Content";
import PropTypes from "prop-types";
import styled from "styled-components";

const PWap = styled.div`
  h3,
  h2,
  p {
    margin-bottom: 1rem;
  }
`;

const AffiliatesPage = ({ title, description, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <div>
      <section className="hero is-primary is-bold">
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="section">
                  <h1 className="title has-text-white is-size-1">{title}</h1>
                  <h2 className="description has-text-white is-size-3">
                    {description}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            <PWap className="column is-5 is-offset-1">
              <h2 className="is-size-3 has-text-info">
                Are you a developer with lots of recruiters contacting you?
              </h2>
              <br />
              <h3 className="is-size-4 has-text-info">
                Want to try to earn some money from this resource?
              </h3>
              <br />
              <p>
                Become an affiliate for ITeach Recruiters. Help me sell my
                course that teaches tech topics to technical recruiters so they
                don't mix up Java and JavaScript anymore!
              </p>
              <p>How much is the commission?</p>
              <div style={{ textAlign: "center" }}>
                <p className="tag is-info is-large">20%</p>
              </div>
              <div>
                <p>
                  That means for every course you sell at $299 you will get
                  $59.80 before fees. The affiliates system is set up through
                  teachable, so payouts and tax forms and everything are
                  automatically handled through the Teachable platform.
                </p>
                <p>
                  And by the way, if you sell more than 10 courses, I will bump
                  your affiliate rate up to 25%.
                </p>
              </div>
            </PWap>
            <PWap className="column is-5 is-offset-1">
              <h2 className="is-size-3 has-text-info">
                Contact me to get started
              </h2>
              <p>Put "iteach recruiters affiliate" in the subject line</p>
              <div>
                <p>
                  Email: <a href="mailto:aaron@ard.ninja">aaron@ard.ninja</a>
                </p>
              </div>
              <div>
                Sorry, I didn't feel like making a form for this. You can just
                email me, I swear it is not that hard!
              </div>
            </PWap>
          </div>
        </div>
      </section>
    </div>
  );
};

AffiliatesPage.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func
};

export default AffiliatesPage;
